import { useStaticQuery } from 'gatsby'; //

var useImage = function useImage() {
  var data = useStaticQuery("2896609628");
  var me = data.meImg.edges[0].node.childImageSharp.fluid;
  return {
    me: me
  };
};

export default useImage;